import React from "react"
import { Row, Col } from "reactstrap"
// import Tooltip from "@material-ui/core/Tooltip"
// import { ServiceDisplayName } from "../../../utils/service-mappings"
// import { makeStyles } from "@material-ui/core/styles"
// import { css } from "glamor"
// import _ from "lodash"

// const classMap = {
//   active: "text-primary",
//   inactive: "text-warning",
//   archived: "text-black-50",
// }

// const useStylesBootstrap = makeStyles(theme => ({
//   arrow: {
//     color: theme.palette.common.black,
//   },
//   tooltip: {
//     backgroundColor: theme.palette.common.black,
//   },
// }))
const areEqual = (prevProps, nextProps) => {
  if (prevProps?.project?.projectSlug === nextProps?.project?.projectSlug) {
    return true
  } else {
    return false
  }
}

const ProjectFormView = React.memo(props => {
  // const classes = useStylesBootstrap()

  // function BootstrapTooltip(props) {
  //   return <Tooltip ref={props.ref} arrow classes={classes} {...props} placement="top-start" />
  // }

  // const overViewImg = (visibility, serviceName) => {
  //   const text = ServiceDisplayName(serviceName)
  //   return (
  //     <>
  //       {visibility ? (
  //         <Col xm={1} sm={1} md={1}>
  //           <BootstrapTooltip
  //             title={text}
  //             placement="top"
  //             {...css({
  //               cursor: "pointer",
  //             })}
  //           >
  //             <img height="30" src={`/${serviceName}.svg`} alt="service" />
  //           </BootstrapTooltip>
  //         </Col>
  //       ) : null}
  //     </>
  //   )
  // }

  const overView = () => {
    const {
      projectSlug,
      visibility,
      // parentOrganization,
      // status,
      // gitlabURL,
      // artifactoryURL,
      // wikiURL,
    } = props.project
    return (
      <div style={{ width: "100%" }}>
        <Row className="mb-12">
          <Col xm={6} sm={6} md={6} className="text-right">
            Project Slug:
          </Col>
          <Col>{projectSlug}</Col>
        </Row>
        <Row className="mb-12">
          <Col xm={6} sm={6} md={6} className="text-right">
            Visibility:
          </Col>
          <Col>{visibility}</Col>
        </Row>
        {/* <Row className="mb-12">
          <Col xm={6} sm={6} md={6} className="text-right">
            Parent Organization:
          </Col>
          <Col>{parentOrganization}</Col>
        </Row>
        <Row className="mb-12">
          <Col xm={6} sm={6} md={6} className="text-right">
            Status:
          </Col>
          <Col className={classMap[status]}>{_.upperFirst(status)}</Col>
        </Row>
        <Row className="mb-12">
          <Col xm={6} sm={6} md={6} className="text-right">
            Services:
          </Col>
          <Col>
            {!!gitlabURL || !!artifactoryURL || !!wikiURL ? (
              <Row>
                {overViewImg(!!gitlabURL, "gitlab")}
                {overViewImg(!!artifactoryURL, "artifactory")}
                {overViewImg(!!wikiURL, "wiki")}
              </Row>
            ) : (
              "None"
            )}
          </Col>
        </Row> */}
      </div>
    )
  }
  return overView()
}, areEqual)

export default ProjectFormView
