import React, { useState, useRef, useEffect } from "react"
import { Form, Button, Row, Col, Tooltip } from "reactstrap"
import Layout from "../../../components/layout/layout"
import Content from "../../../components/content/content"
import Header from "../../../components/support/header/header"
import Footer from "../../../components/support/footer/footer"
import { useUserOrgs } from "../../../hooks/use-user"
import { Loading } from "../../../components/loading"
import { fetch_post } from "../../../utils/fetch"
import { toast } from "react-toastify"
import * as Sentry from "@sentry/gatsby"
import { useAuth0 } from "@auth0/auth0-react"
import SearchHeader from "../../../components/support/input-search/projects"
import ProjectView from "../../../components/support/user/project-view"
import SuccessModal from "../../../components/support/modal/jump-to-servicedesk"
import SuperAdminHeader from "../../../components/support/superadmin-header"

const SUCCESS = 0
const ERROR = 1

const ArchivePublicProject = props => {
  const {
    loading,
    currentOrg: {
      index,
      name,
      role: { isSuperAdmin },
    },
  } = useUserOrgs()
  const { getAccessTokenSilently } = useAuth0()
  const [selectProject, setSelectProject] = useState(null)
  const [savingData, setSavingData] = useState(false)
  const [ticketLink, setTicketLink] = useState("")
  const [buttonTooltip, setButtonTooltip] = useState("")
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const toggle = () => setTooltipOpen(!tooltipOpen)
  const [orgLoading, setOrgLoading] = useState(true)
  const [parentOrganization, setParentOrganization] = useState("")
  const [raiseOnBehalfOf, setRaiseOnBehalfOf] = useState(null)
  const childRef = useRef()

  useEffect(() => {
    if (!loading && !isSuperAdmin) {
      setOrgLoading(false)
    }
  }, [loading, isSuperAdmin])

  useEffect(() => {
    if (selectProject) {
      if (selectProject.visibility !== "public") {
        setButtonTooltip("The specified project is not public.")
      } else if (selectProject.status !== "active") {
        setButtonTooltip("The specified project is not active")
      } else {
        setButtonTooltip("")
      }
    }
  }, [selectProject])

  if (loading) {
    return <Loading />
  }

  async function send_request() {
    let params = {}
    if (!index) {
      params = { parentOrganization }
    }
    return getAccessTokenSilently()
      .then(accessToken =>
        fetch_post(
          index
            ? `ui/support/projects/${selectProject.projectSlug}/archive?index=${index}`
            : `ui/support/projects/${selectProject.projectSlug}/archive`,
          params,
          accessToken
        )
      )
      .then(response => {
        return { status: SUCCESS, description: response.status.description, data: response.data }
      })
      .catch(error => {
        Sentry.captureException(error)
        console.log(`Error while archiving project: ${error}`)
        return { status: ERROR, description: error }
      })
  }

  const handleSubmit = event => {
    event.preventDefault()
    setSavingData(true)
    send_request().then(resultCode => {
      console.log(resultCode, "resultCoderesultCode")
      if (resultCode.status === 0) {
        setTicketLink(resultCode.data._links.web)
        childRef.current.toggle()
      } else {
        toast(`${resultCode.description}`, {
          type: "error",
          position: "bottom-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      }
      setSavingData(false)
    })
  }

  return (
    <Layout pageTitle="Archive Public Project">
      <Header>Archive Public Project</Header>
      <Content>
        {isSuperAdmin && (
          <SuperAdminHeader
            setOrgLoading={load => setOrgLoading(load)}
            parentOrganization={parentOrganization}
            setParentOrganization={parentOrg => setParentOrganization(parentOrg)}
            // setRaiseOnBehalfOf={user => setRaiseOnBehalfOf(user)}
          />
        )}
        {!orgLoading && (
          <>
            <Row className="justify-content-center mb-2">
              <Col xs={{ size: 8 }} className="d-flex justify-content-center align-items-center">
                <SearchHeader
                  selectData={data => setSelectProject(data)}
                  index={index}
                  parentOrganization={parentOrganization}
                />
              </Col>
            </Row>
            {selectProject ? (
              <>
                <Row className="mb-2">
                  <ProjectView
                    project={{ ...selectProject, parentOrganization: name }}
                    orgIndex={index}
                    isSuperAdmin={isSuperAdmin}
                    selectParentOrg={name}
                  />
                </Row>
                <Form method="POST" onSubmit={handleSubmit} className="justify-content-center">
                  <Row className="justify-content-center mb-2" id="wrapper">
                    <Button
                      type="submit"
                      onClick={handleSubmit}
                      disabled={savingData || buttonTooltip || (!index && !parentOrganization)}
                      color={"primary"}
                      style={{ padding: 0 }}
                    >
                      {savingData ? (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        >
                          <span className="sr-only">Saving...</span>
                        </span>
                      ) : (
                        <div id="submit" style={{ padding: "0.375rem 0.75rem" }}>
                          Archive Project
                        </div>
                      )}
                    </Button>
                    <Tooltip
                      placement="top"
                      isOpen={buttonTooltip && tooltipOpen}
                      autohide={false}
                      container="wrapper"
                      target="submit"
                      toggle={toggle}
                    >
                      {buttonTooltip}
                    </Tooltip>
                  </Row>
                </Form>
              </>
            ) : null}
            <SuccessModal ticketLink={ticketLink} cRef={childRef} />
            <Footer />
          </>
        )}
      </Content>
    </Layout>
  )
}

export default ArchivePublicProject
